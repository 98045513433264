<template>
  <div class="container-cental">
    <h1 class="title-vista mt-5 mb-5">{{ $t("messages.contactanos")}}</h1>
    <b-form ref="formContactanos" style="margin-bottom: 30px">
      <div class="row p-3">
        <!-- nombre -->
        <b-form-group
          id="nombre"
          :label="`${$t('messages.nombre')} *`"
          label-for="nombre"
          class="col-md-4"
        >
          <b-form-input
            id="nombre"
            v-model.trim="form.nombre"
            type="text"
            :placeholder="`${$t('messages.nombre')}`"
            ref="nombre"
            :state="nombreState"
            required
          ></b-form-input>
        </b-form-group>
        <!-- email -->
        <b-form-group
          id="email"
          :label="`${$t('messages.email')} *`"
          label-for="email"
          class="col-md-4"
        >
          <b-form-input
            id="email"
            v-model.trim="form.email"
            type="email"
            :placeholder="`${$t('messages.email')}`"
            ref="email"
            :state="emailState"
            required
          ></b-form-input>
        </b-form-group>
        <!-- telefono -->
        <b-form-group
          id="telefono"
          :label="`${$t('messages.numeroTelefono')}`"
          label-for="telefono"
          class="col-md-4"
        >
          <b-form-input
            id="telefono"
            v-model.trim="form.telefono"
            type="text"
            :placeholder="`${$t('messages.numeroTelefono')}`"
          ></b-form-input>
        </b-form-group>
        <!-- Comentario -->
        <b-form-group
          id="comentario"
          :label="`${$t('messages.comentario')} *`"
          label-for="comentario"
          class="col-12"
        >
          <b-form-textarea
            id="comentario"
            v-model.trim="form.comentario"
            :placeholder="`${$t('messages.comentanosAlgo')}`"
            rows="4"
            max-rows="8"
            required
            :maxlength="500"
            :state="comentarioState"
          ></b-form-textarea>
        </b-form-group>
        <!-- verificacion -->
        <b-form-group
          id="verifiacion"
          :label="`${$i18n.locale === 'es'? '¿':''} ${$t('messages.cuantoEs')} ${num1} + ${num2} ${$i18n.locale === 'es'? '?':''}  *`"
          label-for="verifiacion"
          class="col-md-2"
        >
          <b-form-input
            id="verificacion"
            v-model.number="form.verificacion"
            type="number"
            placeholder="verificacion"
            ref="verificacion"
            :state="verificacionState"
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button variant="primary" @click="handleSubmit" :disabled="inProcess"
        >{{ $t("messages.submit") }}</b-button
      >
      <p
        style="margin-top: 30px; font-size: 20px; color: #c9719d"
        v-show="showMessage"
      >
        Mensaje enviado correctamente, nos comunicaremos con usted lo antes
        posible, mil gracias por confiar en nuestros servicios
      </p>
    </b-form>
  </div>
</template>

<script>
import UsersService from "@/services/users.service";
import EventBus from "@/services/eventBus.js";
export default {
  name: "Contactanos",
  data() {
    return {
      usersService: null,
      form: {
        nombre: "",
        email: "",
        telefono: null,
        comentario: "",
        verificacion: 0,
      },
      nombreState: null,
      emailState: null,
      comentarioState: null,
      verificacionState: null,
      num1: this.getRandomArbitrary(1, 20),
      num2: this.getRandomArbitrary(1, 20),
      inProcess: false,
      showMessage: false,
    };
  },
  created() {
    this.usersService = new UsersService();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    handleSubmit() {
      if (!this.validacion()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debes completar los campos obligatorios",
        });
        return false;
      }
      this.inProcess = true;
      this.usersService
        .contactanos(this.form)
        .then((result) => {
          if (result.data.status == 200) {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Mensaje enviado correctamente",
            });
            this.num1 = this.getRandomArbitrary(1, 20);
            this.num2 = this.getRandomArbitrary(1, 20);
            this.form = {
              nombre: "",
              email: "",
              telefono: null,
              comentario: "",
              verificacion: 0,
            };
            this.showMessage = true;
            setTimeout(() => {
              this.showMessage = false;
            }, 5000);
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "warning",
              message: "Error al enviar mensaje",
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "warning",
            message: "Error al enviar mensaje",
          });
          this.inProcess = false;
        });
    },
    validacion() {
      /* nombre */
      const validNombre = this.$refs.nombre.checkValidity();
      this.nombreState = validNombre;
      /* email */
      const validEmail = this.$refs.email.checkValidity();
      this.emailState = validEmail;
      /* comentario */
      const validComentario = this.form.comentario.length > 0;
      this.comentarioState = validComentario;
      /* verificacion */
      const validVerificacion =
        this.$refs.verificacion.checkValidity() &&
        this.form.verificacion === this.num1 + this.num2;
      this.verificacionState = validVerificacion;

      return validNombre && validEmail && validComentario && validVerificacion;
    },
    getRandomArbitrary(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}
@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 10px;
  }
}
</style>
